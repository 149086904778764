import { Vector3 } from 'three';
import { ModelName } from '../../underground/api/PortableAsset.helpers';
import { TagboardTag } from '../../underground/api/Tagboard.validator';
import { Highlighted } from '../../underground/map/MapInteraction/useMapInteraction';
import { stringifyIdRecord } from '../stringUtils';
import {
  AssetBattery,
  AssetCoordinatesRelative,
  AssetEms,
  AssetExtension,
  AssetHeartbeat,
  AssetPairCrossed,
  AssetSensed,
  AssetSensedTriggered,
  AssetWelfareCheck,
  AssetStatus,
  Sensed,
  AssetHeartrate,
  AssetTagboard,
} from './schema';

export interface AssetState {
  id: Record<string, string | undefined>;
  label: string;
  lastUpdate?: Date;
  lastBattery?: Omit<AssetBattery, 'ids'>;
  lastHeartbeat?: Omit<AssetHeartbeat, 'ids'>;
  lastSensed?: Omit<AssetSensed, 'ids'>;
  recentSensed?: (Sensed & { iso8601: string })[];
  lastSensedTriggered?: Omit<AssetSensedTriggered, 'ids'>;
  recentSensedTriggered?: Omit<AssetSensedTriggered, 'ids'>[];
  lastLocation?: Omit<AssetCoordinatesRelative, 'ids'>;
  lastKnownLevelId?: number;
  recentLocations?: Omit<AssetCoordinatesRelative, 'ids'>[];
  lastEms?: Omit<AssetEms, 'ids'>;
  lastStatus?: Omit<AssetStatus, 'ids'>;
  type?: string | null;
  model?: ModelName | null;
  group?: string | null;
  surface?: boolean;
  positionVector?: Vector3 | undefined;
  lastCrossed?: Omit<AssetPairCrossed, 'ids'>;
  pairsCrossed?: Record<string, Omit<AssetPairCrossed, 'ids'>>;
  welfare?: {
    safe?: boolean;
    nearest?: string;
  };
  lastExtensions?: Omit<AssetExtension, 'ids'>;
  lastHeartrate?: Omit<AssetHeartrate, 'ids'>;
  isMicrofence?: boolean;
}
export interface Notification {
  notificationId: string;
  title: string;
  date: Date;
  asset: Highlighted;
  seen: boolean;
}

export const notificationFromEms = (ems: AssetEms, label: string): Notification => {
  const date = new Date(ems.iso8601);
  const id = stringifyIdRecord(ems.ids.id);
  const notificationId = `EMS_${id}_${date.getTime()}`;
  const seen = false; // TODO: Check localStorage
  return {
    notificationId,
    title: 'Environmental Monitoring System Alarm',
    date,
    asset: {
      category: 'sensor',
      id: stringifyIdRecord(ems.ids.id),
      label,
    },
    seen,
  };
};
export const notificationFromDraeger = (
  draeger: AssetStatus,
  severity: 'alarm' | 'warning',
  label: string,
): Notification => {
  const date = new Date(draeger.iso8601);
  const id = stringifyIdRecord(draeger.ids.id);
  const notificationId = `DRAEGER_${id}_${date.getTime()}`;
  const seen = false;
  return {
    notificationId,
    title: `Gas Detector ${severity === 'alarm' ? 'Alarm' : 'Warning'}`,
    date,
    asset: {
      category: 'beacon',
      id: stringifyIdRecord(draeger.ids.id),
      label: label,
    },
    seen,
  };
};

export interface LiveMapState {
  assets: Map<string, AssetState>;
  lastUpdate: Date;
  lastWelfareCheck?: AssetWelfareCheck;
  startDate: Date;
  notifications: Notification[];
  tagboard: Map<string, AssetTagboard | { ids: AssetTagboard['ids'] }>;
}

export enum AssetUpdateType {
  FullStateUpdate = 'FULL_STATE_UPDATE',
  FullStateMerge = 'FULL_STATE_MERGE',
  Battery = 'battery',
  Heartbeat = 'heartbeat',
  Sensed = 'sensed',
  SensedTriggered = 'sensed.triggered',
  CoordinatesRelative = 'coordinates.relative',
  Ems = 'ems',
  Status = 'status',
  WelfareCheck = 'welfare.check',
  PairCrossed = 'pair.crossed',
  Extension = 'extension',
  Heartrate = 'heartrate',
  Tagboard = 'tagboard',
}

export type LiveStateUpdate =
  | {
      type: AssetUpdateType.Battery;
      data: AssetBattery;
    }
  | {
      type: AssetUpdateType.Heartbeat;
      data: AssetHeartbeat;
    }
  | {
      type: AssetUpdateType.Sensed;
      data: AssetSensed;
    }
  | {
      type: AssetUpdateType.SensedTriggered;
      data: AssetSensedTriggered;
    }
  | {
      type: AssetUpdateType.CoordinatesRelative;
      data: AssetCoordinatesRelative;
    }
  | {
      type: AssetUpdateType.Ems;
      data: AssetEms;
    }
  | {
      type: AssetUpdateType.WelfareCheck;
      data: AssetWelfareCheck;
    }
  | {
      type: AssetUpdateType.Status;
      data: AssetStatus;
    }
  | {
      type: AssetUpdateType.PairCrossed;
      data: AssetPairCrossed;
    }
  | {
      type: AssetUpdateType.Extension;
      data: AssetExtension;
    }
  | {
      type: AssetUpdateType.Heartrate;
      data: AssetHeartrate;
    }
  | {
      type: AssetUpdateType.Tagboard;
      data: AssetTagboard;
    };

export type LiveStateOrFullUpdate =
  | {
      type: AssetUpdateType.FullStateUpdate;
      data: LiveMapState;
    }
  | {
      type: AssetUpdateType.FullStateMerge;
      data: LiveMapState;
    }
  | LiveStateUpdate;

export const omitIds = <T extends Record<string, unknown> & { ids: unknown }>(
  obj: T,
): Omit<T, 'ids'> => {
  const { ids, ...rest } = obj;
  return rest;
};
