import { Pause, PlayArrow } from '@mui/icons-material';
import { Fab, Grid, Typography } from '@mui/material';
import { setSeconds, subHours } from 'date-fns';
import { useAtom, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { LIVE_PLAYING, LIVE_SPEED_MULTIPLIER } from '../../../store/liveEvents';
import { REPLAY_MESSAGES, REPLAY_NOW, REPLAY_PLAYING, REPLAY_SECONDS_PER_SECOND, REPLAY_START } from '../../../store/replayEvents';
import { Sidebar, SMALL_WIDTH } from '../../../util/Sidebar/Sidebar';
import { TimeSlider } from '../../../util/TimeSlider/TimeSlider';
import { useMobile } from '../../../util/useMobile';
import { NotificationsToast } from '../../notifications/Notifications';
import { AutoCreateLevelsDialog } from '../EditTools/AutoCreateLevelsDialog';
import { AutoPlaceLocatorsDialog } from '../EditTools/AutoPlaceLocatorsDialog';
import { ConfirmDeleteDialog } from '../EditTools/ConfirmDeleteDialog';
import { EditTools } from '../EditTools/EditTools';
import { RevertAllChangesDialog } from '../EditTools/RevertAllChangesDialog';
import { SaveEditDialog } from '../EditTools/SaveEditDialog';
import { UploadNavmeshDialog } from '../EditTools/UploadNavmeshDialog';
import { Map } from '../Map/Map';
import { MapInterpolationProvider } from '../MapData/useInterpolatedData';
import { MapDataProvider, MapDataReplayProvider } from '../MapData/useMapData';
import { SpeedAdjustmentProvider } from '../MapData/useSpeedAdjustment';
import { MapInteractionProvider } from '../MapInteraction/useMapInteraction';
import { MapViewTools } from '../MapViewTools/MapViewTools';
import {
  useSidebarInteraction,
  Secondary,
  SidebarInteractionProvider,
} from '../SidebarInteraction/useSidebarInteraction';
import { MapBannerOverlay } from '../overlays/MapBannerOverlay';
import { MapHorizontalOverlay } from '../overlays/MapHorizontalOverlay';
import { MapOverlay } from '../overlays/MapOverlay';
import { AccountSidebar } from '../sidebars/AccountSidebar';
import { BeaconSidebar } from '../sidebars/BeaconsSidebar';
import { DeviceSidebar } from '../sidebars/DeviceSidebar';
import { EditBeaconSidebar } from '../sidebars/EditBeaconSidebar';
import { EditBulkSidebar } from '../sidebars/EditBulkSidebar';
import { EditDeviceSidebar } from '../sidebars/EditDeviceSidebar';
import { EditLevelsSidebar } from '../sidebars/EditLevelsSidebar';
import { EditLocatorSidebar } from '../sidebars/EditLocatorSidebar';
import { EditMicrofenceSidebar } from '../sidebars/EditMicrofenceSidebar';
import { EditServiceConfigSidebar } from '../sidebars/EditServiceConfigSidebar';
import { LevelsSidebar } from '../sidebars/LevelsSidebar';
import { LocatorSidebar } from '../sidebars/LocatorSidebar';
import { MapSidebar } from '../sidebars/MapSidebar';
import { NavigationSidebar } from '../sidebars/NavigationSidebar';
import { ReplaySidebar } from '../sidebars/ReplaySidebar';
import { ReportSidebar } from '../sidebars/ReportSidebar';
import { SensorSidebar } from '../sidebars/SensorSidebar';
import { SettingsSidebar } from '../sidebars/SettingsSidebar';
import { WelfareSidebar } from '../sidebars/WelfareSidebar';

export type MapPageArgs = { cid: string, pid: string, mode: 'live' | 'replay' | 'edit' };

const getSecondarySidebar = (visible: Secondary) => {
  if (visible === Secondary.LEVELS) return <LevelsSidebar />;
  if (visible === Secondary.LOCATORS) return <LocatorSidebar />;
  if (visible === Secondary.DEVICES) return <DeviceSidebar />;
  if (visible === Secondary.BEACONS) return <BeaconSidebar />;
  if (visible === Secondary.DIRECTIONS) return <NavigationSidebar />;
  if (visible === Secondary.REPORTS) return <ReportSidebar />;
  if (visible === Secondary.WELFARE) return <WelfareSidebar />;
  if (visible === Secondary.SENSORS) return <SensorSidebar />;
  if (visible === Secondary.MAP_SETTINGS) return <SettingsSidebar />;
  if (visible === Secondary.ACCOUNT) return <Suspense><AccountSidebar /></Suspense>;
  if (visible === Secondary.EDIT_LOCATORS) return <EditLocatorSidebar />;
  if (visible === Secondary.EDIT_BEACONS) return <EditBeaconSidebar />;
  if (visible === Secondary.EDIT_DEVICES) return <EditDeviceSidebar />;
  if (visible === Secondary.EDIT_MICROFENCES) return <EditMicrofenceSidebar />;
  if (visible === Secondary.EDIT_SERVICE_CONFIGS) return <EditServiceConfigSidebar />;
  if (visible === Secondary.EDIT_BULK) return <EditBulkSidebar />;
  if (visible === Secondary.EDIT_LEVELS) return <EditLevelsSidebar />;

  return undefined;
};

const MapPageInternal = () => {
  const { secondary, showSecondary } = useSidebarInteraction();
  const isMobile = useMobile()

  return (
    <>
      <Sidebar
        clickedAway={() => {
          showSecondary(Secondary.NONE);
        }}
        main={<Map mode='live' />}
        sidebar={<MapSidebar mode='live' />}
        secondarySidebar={getSecondarySidebar(secondary)}
      />
      {<Grid style={{ width: '100%', marginTop: isMobile ? '-30px' : '0', textAlign: 'right' }}>
        <img src='/Logo_large.png' alt="Logo Geomoby" width="150px" />
      </Grid>}
      <MapViewTools />
      <MapHorizontalOverlay mode='live' />
      <MapOverlay />
      <NotificationsToast />
    </>
  );
};

const MapPageInternalEdit = () => {
  const { secondary, showSecondary } = useSidebarInteraction();
  const isMobile = useMobile()

  return (
    <>
      <Sidebar
        clickedAway={() => {
          showSecondary(Secondary.NONE);
        }}
        main={<Map mode='edit' />}
        sidebar={<MapSidebar mode='edit' />}
        secondarySidebar={getSecondarySidebar(secondary)}
      />
      {<Grid style={{ width: '100%', marginTop: isMobile ? '-30px' : '0', textAlign: 'right' }}>
        <img src='/Logo_large.png' alt="Logo Geomoby" width="150px" />
      </Grid>}
      <EditTools />
      <MapViewTools />
      <MapHorizontalOverlay mode='edit' />
      <MapOverlay />
      <SaveEditDialog />
      <RevertAllChangesDialog />
      <ConfirmDeleteDialog />
      <UploadNavmeshDialog />
      <AutoPlaceLocatorsDialog />
      <AutoCreateLevelsDialog />
    </>
  );
};

const MapPageInternalReplay = () => {
  const { secondary, showSecondary } = useSidebarInteraction();

  const [initalised, setInitialised] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<Date>(subHours(new Date(), 2));
  const [replayStart, setReplayStart] = useAtom(REPLAY_START);
  useEffect(() => {
    if (startDateTime) {
      setReplayStart(setSeconds(startDateTime, 0));
    }
  }, [setReplayStart, startDateTime]);

  const [playing, setPlaying] = useAtom(REPLAY_PLAYING);
  const [secondsPerSecond, setSecondsPerSecond] = useAtom(REPLAY_SECONDS_PER_SECOND);
  const [now, setNow] = useAtom(REPLAY_NOW);
  const setReplayMessages = useSetAtom(REPLAY_MESSAGES);

  const isMobile = useMobile()

  const replaySidebar = <ReplaySidebar
    secondsPerSecond={secondsPerSecond}
    setSecondsPerSecond={setSecondsPerSecond}
    startDateTime={startDateTime}
    onResetClick={(newStartDateTime: Date) => {
      setInitialised(true);
      setStartDateTime(newStartDateTime);
      setReplayMessages([]);
      setPlaying(false);
      setNow(setSeconds(newStartDateTime, 0));
    }}
  />

  const getSecondaryOrReplaySidebar = (secondary: Secondary) => {
    return secondary === Secondary.REPLAY ? replaySidebar : getSecondarySidebar(secondary)
  }

  useEffect(() => {
    showSecondary(Secondary.REPLAY);
  }, [showSecondary]);

  return (
    <>
      <Sidebar
        clickedAway={() => {
          showSecondary(Secondary.NONE);
        }}
        main={<Map mode='replay' />}
        sidebar={<MapSidebar mode='replay' />}
        secondarySidebar={getSecondaryOrReplaySidebar(secondary)}
      />
      <MapBannerOverlay>
        <Typography variant="h6" style={{ minWidth: 210 }} color={!initalised ? "GrayText" : undefined}>
          {!initalised
            ? startDateTime.toLocaleString(undefined, { hour12: false }).replace(/\d{2}/g, '--').replace(/\d/g, '--')
            : now.toLocaleString(undefined, { hour12: true }).replace(', 0:', ', 12:') // fix for hour 12 in Chrome
          }
        </Typography>
        <Fab
          color="secondary"
          size="small"
          sx={{ marginLeft: 1 }}
          onClick={() => {
            setPlaying(value => !value);
            showSecondary(Secondary.NONE);
          }}
          disabled={!initalised}
        >
          {playing ? <Pause /> : <PlayArrow />}
        </Fab>
      </MapBannerOverlay>
      <TimeSlider
        sx={{ position: 'absolute', top: 43, right: 0, width: isMobile ? '100%' : `calc(100vw - ${SMALL_WIDTH}px)` }}
        current={now}
        disabled={!initalised}
        onSelected={(newDate) => {
          let wasPlaying = false;
          setPlaying(val => {
            wasPlaying = val;
            return false;
          });
          setInitialised(true);
          setStartDateTime(newDate);
          setReplayMessages([]);
          setNow(setSeconds(newDate, 0));
          if (wasPlaying) {
            setTimeout(() => setPlaying(true), 200);
          }
        }} />
      <MapHorizontalOverlay mode='replay' />
      {<Grid style={{ width: '100%', marginTop: isMobile ? '30px' : '60px', textAlign: 'right' }}>
        <img src='/Logo_large.png' alt="Logo Geomoby" width="150px" />
      </Grid>}
      <MapViewTools />
      <MapOverlay />
      <NotificationsToast />
    </>
  );
};

// For testing
export const MapPageWithoutData = () => (
  <MapInteractionProvider>
    <SidebarInteractionProvider>
      <MapPageInternal />
    </SidebarInteractionProvider>
  </MapInteractionProvider>
);

export const MapPage = ({ cid, pid, mode }: MapPageArgs) => (
  <MapInterpolationProvider>
    <MapInteractionProvider>
      {mode === 'replay'
        ? (
          <SpeedAdjustmentProvider speedAtom={REPLAY_SECONDS_PER_SECOND} playingAtom={REPLAY_PLAYING} >
            <MapDataReplayProvider cid={cid} pid={pid} >
              <SidebarInteractionProvider>
                <MapPageInternalReplay />
              </SidebarInteractionProvider>
            </MapDataReplayProvider>
          </SpeedAdjustmentProvider>
        )
        : mode === 'edit'
          ? (
            <MapDataProvider cid={cid} pid={pid} >
              <SidebarInteractionProvider>
                <MapPageInternalEdit />
              </SidebarInteractionProvider>
            </MapDataProvider>
          )
          : (
            <SpeedAdjustmentProvider speedAtom={LIVE_SPEED_MULTIPLIER} playingAtom={LIVE_PLAYING} >
              <MapDataProvider cid={cid} pid={pid} >
                <SidebarInteractionProvider>
                  <MapPageInternal />
                </SidebarInteractionProvider>
              </MapDataProvider>
            </SpeedAdjustmentProvider>
          )
      }
    </MapInteractionProvider>
  </MapInterpolationProvider>
);
