import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Grid, TextField, Typography, Slider, Paper, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_CLAMPING_THRESHOLD_METRES, DEFAULT_ENVIRONMENTAL_FACTOR, DEFAULT_OBS_LOOKBACK_SECONDS, DEFAULT_SCANNING_INTERVAL_SECONDS, DEFUALT_SCANNING_WINDOW_SECONDS } from '../../../config';
import { CheckboxAutoFormLabel } from '../../../util/FormControl/AutoFormLabel';
import { ServiceConfig } from '../../api/ServiceConfig.validator';
import { useEditingData } from './useEditingData';

const getUndergrounConfigNumber = <T,>(stateJson: unknown, what: string, fallback: T) => {
  const n = Number(Object(stateJson).underground_config?.[what]);
  return isNaN(n) ? fallback : n;
}

export const EditInfoServiceConfig = ({ asset }: { asset: ServiceConfig }) => {
  const { setCurrentEdit, dispatchServiceConfigEdit, setCurrentDeletion } = useEditingData();
  const [newName, setNewName] = useState<string>(asset.name);

  const isForUnderground = 'underground_config' in Object(asset.state_json)

  const [newScanningInterval, setNewScanningInterval] = useState<number>(getUndergrounConfigNumber(asset.state_json, 'scanning_interval', DEFAULT_SCANNING_INTERVAL_SECONDS));
  const [newScanningWindow, setNewScanningWindow] = useState<number>(getUndergrounConfigNumber(asset.state_json, 'scanning_window', DEFUALT_SCANNING_WINDOW_SECONDS));
  const [newObsLookBackSeconds, setNewObsLookBackSeconds] = useState<number | undefined>(getUndergrounConfigNumber(asset.state_json, 'obsLookBackSeconds', undefined));
  const [newEnvironmentalFactor, setNewEnvironmentalFactor] = useState<number | undefined>(getUndergrounConfigNumber(asset.state_json, 'environmentalFactor', undefined));
  const [newClampingThresholdMetres, setNewClampingThresholdMetres] = useState<number | undefined>(getUndergrounConfigNumber(asset.state_json, 'clampingThresholdMetres', undefined));

  const [newApplyTo, setNewApplyTo] = useState<Record<string, string>[] | undefined>(Object(asset.state_json)?.apply_to);
  const [expandedApplyTo, expandApplyTo] = useState(false);

  const [newInTimeWindows, setNewInTimeWindows] = useState<{ start: string, duration: string, zone_id: string }[] | undefined>(Object(asset.state_json)?.in_time_windows);
  const [expandedInTimeWindows, expandInTimeWindows] = useState(false);

  const isValid = useMemo(() =>
    newName.length &&
    !isNaN(newScanningWindow) && newScanningWindow > 0 &&
    !isNaN(newScanningInterval) && newScanningInterval > 0 &&
    (newObsLookBackSeconds === undefined ? true : !isNaN(newObsLookBackSeconds) && newObsLookBackSeconds > 0) &&
    (newEnvironmentalFactor === undefined ? true : !isNaN(newEnvironmentalFactor) && newEnvironmentalFactor > 0) &&
    (newClampingThresholdMetres === undefined ? true : !isNaN(newClampingThresholdMetres) && newClampingThresholdMetres > 0),

    [newName, newScanningWindow, newScanningInterval, newObsLookBackSeconds, newEnvironmentalFactor, newClampingThresholdMetres]
  );

  useEffect(() => { // handle asset changing while open
    setNewName(asset.name);
    setNewScanningWindow(getUndergrounConfigNumber(asset.state_json, 'scanning_window', DEFUALT_SCANNING_WINDOW_SECONDS));
    setNewScanningInterval(getUndergrounConfigNumber(asset.state_json, 'scanning_interval', DEFAULT_SCANNING_INTERVAL_SECONDS));
    setNewObsLookBackSeconds(getUndergrounConfigNumber(asset.state_json, 'obsLookBackSeconds', undefined));
    setNewEnvironmentalFactor(getUndergrounConfigNumber(asset.state_json, 'environmentalFactor', undefined));
    setNewClampingThresholdMetres(getUndergrounConfigNumber(asset.state_json, 'clampingThresholdMetres', undefined));
    setNewApplyTo(Object(asset.state_json)?.apply_to);
    setNewInTimeWindows(Object(asset.state_json)?.in_time_windows);
  }, [asset.name, asset.state_json])

  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    const update: ServiceConfig = {
      ...asset,
      name: newName,
      state_json: {
        ...Object(asset.state_json),
        underground_config: {
          scanning_window: newScanningWindow,
          scanning_interval: newScanningInterval,
          obsLookBackSeconds: newObsLookBackSeconds,
          environmentalFactor: newEnvironmentalFactor,
          clampingThresholdMetres: newClampingThresholdMetres,
        },
        apply_to: newApplyTo,
        in_time_windows: newInTimeWindows,
      },
    };
    dispatchServiceConfigEdit(update)
    setCurrentEdit(undefined);

  }, [isValid, asset, setCurrentEdit, dispatchServiceConfigEdit, newName, newScanningWindow, newScanningInterval, newObsLookBackSeconds, newClampingThresholdMetres, newApplyTo, newInTimeWindows, newEnvironmentalFactor])

  const requestDeletion = useCallback(() => {
    setCurrentDeletion(asset)
  }, [asset, setCurrentDeletion])

  const revertChanges = useCallback(() => {
    dispatchServiceConfigEdit({ id: asset.id, revert: true });
    setCurrentEdit(undefined);
  }, [asset.id, setCurrentEdit, dispatchServiceConfigEdit]);


  return (<>
    <Grid item maxWidth="100%">
      <TextField label="Name" variant="outlined" fullWidth value={newName} required onChange={e => setNewName(e.target.value)} disabled={!isForUnderground} />
    </Grid>
    {isForUnderground && (
      <Grid item maxWidth="100%" container direction="row" spacing={2} alignItems='center' pr={1}>
        <Grid item xs={6}>
          <CheckboxAutoFormLabel checked disabled text="Scanning interval" value={newScanningInterval} unit='second' units='seconds' />
        </Grid>
        <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
          <Slider
            aria-label="Scanning interval"
            value={newScanningInterval}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={30}
            onChange={(event, value) => setNewScanningInterval(Array.isArray(value) ? value[0] : value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxAutoFormLabel checked disabled text="Scanning window" value={newScanningWindow} unit='second' units='seconds' />
        </Grid>
        <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
          <Slider
            aria-label="Scanning window"
            value={newScanningWindow}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={30}
            onChange={(event, value) => setNewScanningWindow(Array.isArray(value) ? value[0] : value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxAutoFormLabel checked={newObsLookBackSeconds !== undefined} text="Observation lookback" value={newObsLookBackSeconds} unit='second' units='seconds'
            onChange={((checked) => setNewObsLookBackSeconds(checked ? DEFAULT_OBS_LOOKBACK_SECONDS : undefined))} />
        </Grid>
        <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
          <Slider
            aria-label="Observation lookback"
            value={newObsLookBackSeconds ?? 0}
            disabled={newObsLookBackSeconds === undefined}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={30}
            onChange={(event, value) => setNewObsLookBackSeconds(Array.isArray(value) ? value[0] : value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxAutoFormLabel checked={newEnvironmentalFactor !== undefined} text="Environmental factor" value={newEnvironmentalFactor} unit='' units=''
            onChange={((checked) => setNewEnvironmentalFactor(checked ? DEFAULT_ENVIRONMENTAL_FACTOR : undefined))} />
        </Grid>
        <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
          <Slider
            aria-label="Environmental factor"
            value={newEnvironmentalFactor ?? 0}
            disabled={newEnvironmentalFactor === undefined}
            valueLabelDisplay="auto"
            step={1}
            min={10}
            max={50}
            onChange={(event, value) => setNewEnvironmentalFactor(Array.isArray(value) ? value[0] : value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxAutoFormLabel checked={newClampingThresholdMetres !== undefined} text="Clamping threshold" value={newClampingThresholdMetres} unit='metre' units='metres'
            onChange={((checked) => setNewClampingThresholdMetres(checked ? DEFAULT_CLAMPING_THRESHOLD_METRES : undefined))} />
        </Grid>
        <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
          <Slider
            aria-label="Clamping threshold"
            value={newClampingThresholdMetres ?? 0}
            disabled={newClampingThresholdMetres === undefined}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={30}
            onChange={(event, value) => setNewClampingThresholdMetres(Array.isArray(value) ? value[0] : value)}
          />
        </Grid>
        {!!newApplyTo /* Only show when already set (remove and just always show when implementing UR-894) */ && <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CheckboxAutoFormLabel disabled checked={!!newApplyTo} text="Asset filter" value={newApplyTo?.length || undefined} unit='asset' units='assets'
              onChange={(checked) => { setNewApplyTo(checked ? [] : undefined); expandApplyTo(checked) }} />
            <IconButton disabled={!newApplyTo} color='inherit' onClick={() => expandApplyTo(prev => !prev)}>
              {expandedApplyTo ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          {expandedApplyTo && (<Grid item xs={12}>
            <Paper elevation={4} sx={{ marginLeft: 2, marginTop: -1, padding: 1 }}>
              <Typography component='pre'>
                {JSON.stringify(newApplyTo ?? [], null, 4)}
              </Typography>
            </Paper>
          </Grid>)}
        </>}
        {!!newInTimeWindows /* Only show when already set (remove and just always show when implementing UR-894) */ && <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CheckboxAutoFormLabel disabled checked={!!newInTimeWindows} text="Time filter" value={newInTimeWindows?.length || undefined} unit='schedule' units='schedules'
              onChange={(checked) => { setNewInTimeWindows(checked ? [] : undefined); expandInTimeWindows(checked) }} />
            <IconButton disabled={!newInTimeWindows} color='inherit' onClick={() => expandInTimeWindows(prev => !prev)}>
              {expandedInTimeWindows ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          {expandedInTimeWindows && (<Grid item xs={12}>
            <Paper elevation={4} sx={{ marginLeft: 2, marginTop: -1, padding: 1 }}>
              <Typography component='pre'>
                {JSON.stringify(newInTimeWindows ?? [], null, 4)}
              </Typography>
            </Paper>
          </Grid>)}
        </>}
      </Grid>
    )}
    <Grid item container maxWidth="100%">
      {isForUnderground && <>
        <Button variant="contained" disabled={!isValid} onClick={dispatchEdit}>Set</Button>
        <Button variant="outlined" onClick={revertChanges}>Undo changes</Button>
        <Button color='warning' variant="outlined" onClick={requestDeletion}>Delete</Button>
      </>}
      {!isForUnderground && <Typography variant='caption' color='error.light'>On-surface service configurations can not be edited</Typography>}
    </Grid>
  </>
  );
};
