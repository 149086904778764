import { Atom, PrimitiveAtom, useAtomValue } from 'jotai';
import { groupBy, sortedUniqBy, uniqBy } from 'lodash';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { BEACON_RECENT_SECONDS, DEFAULT_BEACON_MODEL, DEFAULT_DEVICE_MODEL } from '../../config';
import {
  AssetState,
  AssetUpdateType,
  LiveMapState,
  LiveStateOrFullUpdate,
  LiveStateUpdate,
  Notification,
  notificationFromDraeger,
  notificationFromEms,
  omitIds,
} from '../../util/Events/Messages';
import { AssetTagboard, StatusType } from '../../util/Events/schema';
import { stringifyIdRecord } from '../../util/stringUtils';
import { recordOfStringsFromObject } from '../../util/typeUtils';
import { MapData } from '../map/MapData/useMapData';
import { toKnownModelOrUndefined } from './PortableAsset.helpers';

export type StreamData = {
  state: LiveMapState;
  startDate: Date;
};

export const INITIAL_STATE: LiveMapState = {
  assets: new Map<string, AssetState>(),
  lastUpdate: new Date(), // useAtomValue(STREAM_NOW)
  startDate: new Date(),
  notifications: [],
  tagboard: new Map<string, AssetTagboard>(),
};

export const intitalStateFromStaticMap = (staticMap: MapData, startDate: Date): LiveMapState => {
  const portableAssetStates: [string, AssetState][] = staticMap.portableAssets.map(asset => [
    stringifyIdRecord(
      asset.ids ? recordOfStringsFromObject(asset.ids) : { [`${asset.type}Id`]: asset.id },
    ),
    {
      id: asset.ids
        ? recordOfStringsFromObject(asset.ids)
        : {
            [`${asset.type}Id`]: asset.id,
          },
      label: asset.label ?? asset.id,
      type: asset.type,
      model:
        toKnownModelOrUndefined(asset.model) ??
        (asset.type !== 'beacon' ? DEFAULT_DEVICE_MODEL : DEFAULT_BEACON_MODEL),
      group: asset.group,
      lastUpdate: undefined,
    },
  ]);
  const locatedAssetStates: [string, AssetState][] = staticMap.locatedAssets.map(asset => [
    stringifyIdRecord({ [`${asset.type}Id`]: asset.id }),
    {
      id: {
        [`${asset.type}Id`]: asset.id,
      },
      label: asset.label ?? (asset.type === 'gateway' ? 'Gateway' : asset.id),
      positionVector: asset.position,
      type: asset.type,
      lastUpdate: undefined,
      surface: asset.surface,
      isMicrofence: !!(
        staticMap.microfences.find(m => m.assetId[`${asset.type}Id`] === asset.id) ||
        staticMap.microfencePairs.find(
          mp =>
            mp.upstreamId[`${asset.type}Id`] === asset.id ||
            mp.downstreamId[`${asset.type}Id`] === asset.id,
        )
      ),
    },
  ]);
  const microfencePairs: [string, AssetState][] = staticMap.microfencePairs.map(pair => [
    stringifyIdRecord({ pairId: pair.id }),
    {
      id: {
        pairId: pair.id,
      },
      label: pair.name ?? pair.id,
      lastUpdate: undefined,
    },
  ]);

  const assets = new Map<string, AssetState>([
    ...portableAssetStates,
    ...locatedAssetStates,
    ...microfencePairs,
  ]);

  const tagboard = new Map<string, AssetTagboard | { ids: AssetTagboard['ids'] }>(
    staticMap.tagboard.map(tag => {
      const afterStart = new Date(tag.updatedAt).getTime() > startDate.getTime() + 30_000;
      const assetTag: AssetTagboard = {
        ids: {
          clientId: tag.clientId,
          projectId: tag.projectId,
          id: tag.id,
          label: tag.label ?? undefined,
        },
        iso8601: tag.updatedAt,
        state: tag.state === 'IN' ? 'IN' : 'OUT',
        reason: tag.reason,
      };
      return [stringifyIdRecord(tag.id), afterStart ? { ids: assetTag.ids } : assetTag];
    }),
  );

  return {
    assets,
    lastUpdate: startDate,
    startDate,
    notifications: [],
    tagboard,
  };
};

const emptAssetState = (update: LiveStateUpdate): AssetState => ({
  id: update.data.ids.id,
  label: update.data.ids.label ?? Object.values(update.data.ids.id).join(),
  lastUpdate: undefined,
});

const mapInsert = <K, V>(key: K, val: V, map: Map<K, V>) => {
  map.set(key, val);
  return map;
};

export const liveStateReducer = (state: LiveMapState, update: LiveStateOrFullUpdate) => {
  if (update.type === AssetUpdateType.FullStateUpdate) {
    return update.data;
  } else if (update.type === AssetUpdateType.FullStateMerge) {
    const merged: LiveMapState = {
      assets: new Map<string, AssetState>(),
      lastUpdate:
        update.data.lastUpdate > state.lastUpdate ? update.data.lastUpdate : state.lastUpdate,
      startDate: state.startDate,
      notifications: uniqBy(
        [...update.data.notifications, ...state.notifications],
        'notificationId',
      ).sort((a, b) => a.date.getTime() - b.date.getTime()),
      tagboard: new Map(
        sortedUniqBy(
          [
            ...Array.from(update.data.tagboard.entries()),
            ...Array.from(state.tagboard.entries()),
          ].sort(
            ([_id_a, a], [_id_b, b]) =>
              new Date('iso8601' in b ? b.iso8601 : 0).getTime() -
              new Date('iso8601' in a ? a.iso8601 : 0).getTime(),
          ),
          ([id, _tag]) => id,
        ),
      ),
    };
    state.assets.forEach((asset, key) => {
      const fromUpdate: Partial<AssetState> = update.data.assets.get(key) || {};
      const assetDefinedValues: Partial<AssetState> = Object.fromEntries(
        Object.entries(asset).filter(([_, v]) => v !== null && v !== undefined),
      );
      const updatedAsset: AssetState = {
        ...fromUpdate,
        ...assetDefinedValues,
        id: asset.id,
        label: asset.label,
        lastUpdate: asset.lastUpdate ?? fromUpdate?.lastUpdate,
      };
      merged.assets.set(key, updatedAsset);
    });
    update.data.assets.forEach((assetUpdate, key) => {
      if (merged.assets.has(key)) return;
      merged.assets.set(key, assetUpdate);
    });

    if (update.data.lastWelfareCheck && state.lastWelfareCheck) {
      merged.lastWelfareCheck =
        new Date(update.data.lastWelfareCheck.iso8601) > new Date(state.lastWelfareCheck.iso8601)
          ? update.data.lastWelfareCheck
          : state.lastWelfareCheck;
    } else {
      merged.lastWelfareCheck = update.data.lastWelfareCheck ?? state.lastWelfareCheck;
    }
    return merged;
  } else if (update.type === AssetUpdateType.WelfareCheck) {
    return {
      ...state,
      lastWelfareCheck: update.data,
    };
  }

  const aid = stringifyIdRecord(update.data.ids.id);
  if (!aid) {
    return state;
  }
  const toUpdate = state.assets.get(aid) || emptAssetState(update);

  let updatedAsset: AssetState | null = null;

  const newNotifications: Notification[] = [];

  switch (update.type) {
    case AssetUpdateType.Battery: {
      // HACK for umerc/trial due to firmware not reporting voltages correctly (UR-746/UR-770)
      const updateData = omitIds(update.data);
      if (
        update.data.ids.clientId === 'umerc' &&
        update.data.ids.projectId === 'trial' &&
        !!update.data.ids.id['locatorId']
      ) {
        updateData.percent = 95;
      }
      updatedAsset = {
        ...toUpdate,
        lastBattery: updateData, // omitIds(update.data),
      };
      break;
    }

    case AssetUpdateType.Heartbeat: {
      updatedAsset = {
        ...toUpdate,
        lastHeartbeat: omitIds(update.data),
      };
      break;
    }

    case AssetUpdateType.CoordinatesRelative: {
      const prevLocations = toUpdate.recentLocations ?? [];
      const nextLocation = omitIds(update.data);
      const isRepeatLocation = prevLocations.find(
        l => JSON.stringify(l) === JSON.stringify(nextLocation),
      );

      const updatedRecentLocations = isRepeatLocation
        ? prevLocations
        : [nextLocation, ...prevLocations.slice(0, 29)];

      updatedAsset = {
        ...toUpdate,
        lastLocation: omitIds(update.data),
        recentLocations: updatedRecentLocations,
      };
      break;
    }

    case AssetUpdateType.Sensed: {
      const recentSensed =
        toUpdate.recentSensed?.filter(
          ({ iso8601 }) =>
            new Date(update.data.iso8601).getTime() - new Date(iso8601).getTime() <
            BEACON_RECENT_SECONDS * 1000,
        ) ?? [];
      if (
        toUpdate.type === 'mqttgateway' ||
        toUpdate.type === 'rfiReader' ||
        toUpdate.isMicrofence
      ) {
        recentSensed.unshift(
          ...(update.data.sensed ?? []).map(s => ({ ...s, iso8601: update.data.iso8601 })),
        );
      }

      updatedAsset = {
        ...toUpdate,
        lastSensed: omitIds(update.data),
        recentSensed,
      };
      break;
    }

    case AssetUpdateType.Ems: {
      updatedAsset = {
        ...toUpdate,
        lastEms: omitIds(update.data),
      };

      if (update.data.breach) {
        newNotifications.push(notificationFromEms(update.data, toUpdate.label));
      }

      break;
    }

    case AssetUpdateType.Status: {
      updatedAsset = {
        ...toUpdate,
        lastStatus: omitIds(update.data),
      };

      if (update.data.type === StatusType.DRAEGER) {
        // statusId is the Draeger status byte, see https://geomoby.atlassian.net/wiki/spaces/BG/pages/2120384513/Integration+Draeger
        const statusId = update.data.statusId;
        const alarm = ((statusId >> 1) & 1) === 1;
        const gasAlarm = ((statusId >> 2) & 1) === 1;
        const errorState = ((statusId >> 3) & 1) === 1;
        const calibrationNeeded = ((statusId >> 4) & 1) === 1;
        const bumptestNeeded = ((statusId >> 5) & 1) === 1;
        const alarmOrWarning =
          alarm || gasAlarm || errorState || calibrationNeeded || bumptestNeeded;

        if (alarmOrWarning) {
          const severity =
            alarm || gasAlarm || errorState ? ('alarm' as const) : ('warning' as const);
          newNotifications.push(notificationFromDraeger(update.data, severity, toUpdate.label));
        }
      }

      break;
    }

    case AssetUpdateType.PairCrossed: {
      updatedAsset = {
        ...toUpdate,
        lastCrossed: omitIds(update.data),
        pairsCrossed: {
          ...(toUpdate.pairsCrossed ?? {}),
          [update.data.pairIds.id]: omitIds(update.data),
        },
      };
      break;
    }

    case AssetUpdateType.Extension: {
      updatedAsset = {
        ...toUpdate,
        lastExtensions: omitIds(update.data),
      };
      break;
    }

    case AssetUpdateType.Heartrate: {
      updatedAsset = {
        ...toUpdate,
        lastHeartrate: omitIds(update.data),
      };
      break;
    }

    case AssetUpdateType.Tagboard: {
      state.tagboard.set(aid, update.data);
      break;
    }

    default: {
      return state;
    }
  }

  if (!updatedAsset) return state;

  const updateDate = new Date(update.data.iso8601);
  if (!updatedAsset.lastUpdate) {
    updatedAsset.lastUpdate = updateDate;
  } else {
    updatedAsset.lastUpdate =
      updateDate.getTime() > updatedAsset.lastUpdate.getTime()
        ? updateDate
        : updatedAsset.lastUpdate;
  }

  // Only keep the latest notification for each asset
  const notifications = newNotifications.length
    ? Object.values(groupBy([...state.notifications, ...newNotifications], n => n.asset.id)).map(
        notificationsForAsset => notificationsForAsset[notificationsForAsset.length - 1],
      )
    : state.notifications;

  return {
    ...state,
    assets: mapInsert(aid, updatedAsset, state.assets),
    lastUpdate: updateDate.getTime() > state.lastUpdate.getTime() ? updateDate : state.lastUpdate,
    notifications,
  };
};

export const useGeomobyLiveStream = (
  staticMap: MapData | undefined,
  lastEventsFromPersistorAtom: PrimitiveAtom<LiveStateUpdate[]>,
  liveUpdatesLatestsAtom: Atom<LiveStateUpdate[]>,
): StreamData => {
  const startDate = useMemo(() => new Date(), []);
  const [liveState, liveDispatch] = useReducer(liveStateReducer, INITIAL_STATE);
  const lastLMU = useRef<LiveStateUpdate[]>([]);

  const lastEventsFromPersistor = useAtomValue(lastEventsFromPersistorAtom);

  // Resets the initial state from the static map data.
  // "Full state update" clears an old data, e.g. from changing projects.
  useEffect(() => {
    let effectTimoutId: NodeJS.Timeout;
    if (staticMap) {
      effectTimoutId = setTimeout(() => {
        const updateEvent: LiveStateOrFullUpdate = {
          type: AssetUpdateType.FullStateUpdate,
          data: intitalStateFromStaticMap(staticMap, new Date()),
        };
        liveDispatch(updateEvent);
      }, 5);
    }

    return () => {
      if (effectTimoutId) {
        clearTimeout(effectTimoutId);
      }
    };
  }, [staticMap]);

  // Sets the initial state from the initial batch of events.
  // "Full state merge" does not clear data from livestream message that
  // arrive before the last events from persistor have been fetched.
  useEffect(() => {
    let effectTimoutId: NodeJS.Timeout;
    if (lastEventsFromPersistor && staticMap) {
      effectTimoutId = setTimeout(() => {
        const msgs: LiveStateUpdate[] = lastEventsFromPersistor;
        const actualInitialState: LiveMapState = msgs.reduce(
          (state: LiveMapState, msg: LiveStateUpdate) => liveStateReducer(state, msg),
          intitalStateFromStaticMap(staticMap, new Date()),
        );
        const updateEvent: LiveStateOrFullUpdate = {
          type: AssetUpdateType.FullStateMerge,
          data: actualInitialState,
        };
        liveDispatch(updateEvent);
        lastLMU.current = [...lastLMU.current, ...msgs];
      }, 10);
    }

    return () => {
      if (effectTimoutId) {
        clearTimeout(effectTimoutId);
      }
    };
  }, [lastEventsFromPersistor, staticMap]);

  const latestEvents = useAtomValue(liveUpdatesLatestsAtom);

  // Dispatches live events as they come in
  useEffect(() => {
    let cancelled = false;
    let effectTimoutId: NodeJS.Timeout;

    if (latestEvents && latestEvents.length) {
      effectTimoutId = setTimeout(() => {
        if (cancelled) return;

        latestEvents.forEach(event => {
          const alreadyDispatched = lastLMU.current.find(
            p => JSON.stringify(p) === JSON.stringify(event),
          );
          if (!alreadyDispatched) {
            liveDispatch(event);
          }
        });
        lastLMU.current = latestEvents;
      }, 10);
    }
    return () => {
      cancelled = true;
      if (effectTimoutId) {
        clearTimeout(effectTimoutId);
      }
    };
  }, [latestEvents]);

  return {
    state: liveState,
    startDate,
  };
};
